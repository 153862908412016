import "./about.scss";
import { Link } from "react-router-dom";
import FaAngleLeft from "../../../assets/img/angle-left.svg";
import partnerLogo from "../../../assets/img/partner.svg";

const About = ({}) => {
function openWidgetArticle(articleId) {
    window.FreshworksWidget("open", "article", { id: articleId });
  }

  return (
    <div className="userWrapper">
      <div className="commonWrapper about-page">
        <Link to="/account">
          <h3>
            <img className="img-fluid me-2" src={FaAngleLeft} />
            About
          </h3>
        </Link>
        <hr />
        <p className="mt-8">
          <strong className="font-18">
            Smart Insurance Health & Wellbeing services are provided by Medipartner
            Ltd and our partners providing you health & wellbeing benefits.
          </strong>
        </p>
        {/* <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
          fermentum turpis eu ex commodo, et pretium risus elementum. In sodales
          luctus magna, a aliquet erat porttitor ut. Nunc egestas varius turpis
          id mollis. Morbi vel egestas neque, et efficitur nulla. Nunc ex nisi,
          consectetur non odio vitae, blandit vulputate urna.
        </p> */}
        <div className="outer-link pl">
          <a to="#" className="custom-link" onClick={() => openWidgetArticle(101000491568)}>Read our privacy policy</a>
          {/* <Link to="/privacy-policy">Read our privacy policy</Link> */}
          {/* <Link to="/terms">Read our terms and conditions</Link> */}
          <a to="#" className="custom-link" onClick={() => openWidgetArticle(101000491567)}>Read our terms and conditions</a>
        </div>
      </div>
      <div className="partnerShip">
        <p>In partnership with</p>
        <img className="img-fluid" src={partnerLogo} />
      </div>
    </div>
  );
};

export default About;
