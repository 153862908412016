import "./App.scss";
import Header from "./component/Header/Header";
import Footer from "./component/Footer/Footer";
import allRoutes from "./Routes/allRoutes";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import About from "../container/account/about/about";
//commit
import About from "./container/account/about/about";
import { DataProvider } from "./store";
import ProtectedRoutes from "./container/login/ProtectedRoutes";
import Login from "./container/login/login";
import Register from "./container/Register/Register";
import VerifyCode from "./container/Register/verify-code";
import CreatePassword from "./container/Register/CreatePassword";
import Authenticate from "./component/Authenticate";
import ForgotPassword from "./container/ForgotPassword/ForgotPassword";
import SymptomChecker from "./container/SymptomChecker/SymptomChecker";
import ScrollToTop from "./component/ScrollToTop";
import { useEffect, useState } from "react";
import LogoutTimer from "./sessiontimeout";
// import socket from "./utils/config/socket";


function App() {
  const email = sessionStorage.getItem("email");
  window.FreshworksWidget("hide", "launcher");

  // useEffect(() => {
  //   socket.on("logout", async (data) => {
  //     console.log(data);
  //     handleLogout(email, true);
  //   });
  //   if (email) {
  //     socket.emit("joinRoom", email.toString());
  //   }
  // }, []);


  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <LogoutTimer timeout={900000} />
        <DataProvider>
          {/* <ScrollToTop /> */}
          <div className="mainWrapper">
            <Header />
            <div className="innerWrapper">
              <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/register" element={<Register />} />
                <Route
                  exact
                  path="/register/verify-code"
                  element={<VerifyCode />}
                />
                <Route
                  exact
                  path="/register/create-password"
                  element={<CreatePassword />}
                />
                <Route exact path="/authenticate" element={<Authenticate />} />
                <Route
                  exact
                  path="/forgot-password"
                  element={<ForgotPassword />}
                />
                <Route
                  exact
                  path="/symptom-checker"
                  element={<SymptomChecker />}
                />

                <Route element={<ProtectedRoutes />}>
                  {allRoutes?.map((route, id) => (
                    <Route
                      path={route.path}
                      element={<route.component />}
                      key={id}
                      exact
                    />
                  ))}
                </Route>
              </Routes>
            </div>
            {/* <ToastContainer
            position="top-right"
            autoClose={5000}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            />
          <ToastContainer /> */}
            <Footer />
          </div>
        </DataProvider>
      </div>
      <ToastContainer
        position="bottom-center"
        theme="colored"
        // transition="zoom"
        hideProgressBar={true}
        newestOnTop={false}
        // closeOnClick={true}
        // rtl={false}
        // pauseOnFocusLoss
        // draggable
        // pauseOnHover
      />
    </Router>
  );
}

export default App;
